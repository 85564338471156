<template>
  <CommonPage id="terms" title="Terms of services">
    <section id="terms-content">
      <div class="common-content-head">
        Welcome to AnswerBookhub.homes
      </div>
      <div class="common-content-row">
        Welcome to AnswerBookhub.homes (hereinafter referred to as "the Website"). By using the
        Website, you agree to comply with and be bound by the following terms of service. Please
        read these terms carefully, and if you do not agree to them, do not use the Website.
      </div>
      <div class="common-content-head">
        <span>1</span> Terms of Use
      </div>
      <div class="common-content-row">
        <b>1.1-</b> You must be at least 18 years old or use the Website under the supervision of a
        parent
        or guardian.<br>
        <b>1.2-</b> You agree not to use the Website for any unlawful or unauthorized
        activities.<br>
        <b>1.3-</b> You must not interfere with or disrupt the normal operation of the Website.
      </div>

      <div class="common-content-head">
        <span>2</span> Intellectual Property
      </div>
      <div class="common-content-row">
        <b>2.1-</b> All content on the Website, including but not limited to text, images, graphics,
        and
        code, is the property of the Website or its content providers and is protected by copyright
        laws.<br>
        <b>2.2-</b> You may not copy, distribute, or otherwise use any content from the Website
        without our
        express written permission.
      </div>

      <div class="common-content-head">
        <span>3</span> User-Generated Content
      </div>
      <div class="common-content-row">
        <b>3.1-</b> Any content you post on the Website will be considered non-confidential and
        non-proprietary.<br>
        <b>3.2-</b> You grant the Website a global, perpetual, irrevocable, royalty-free license to
        use,
        reproduce, modify, publish, and distribute any content you provide.
      </div>

      <div class="common-content-head">
        <span>4</span> Disclaimers
      </div>
      <div class="common-content-row">
        <b>4.1-</b> The information on the Website is generated by ChatGPT and is for reference
        only; it
        does not constitute professional advice.<br>
        <b>4.2-</b> The Website does not guarantee the accuracy, completeness, or timeliness of the
        information and is not liable for any loss or damage resulting from reliance on such
        information.
      </div>

      <div class="common-content-head">
        <span>5</span> Termination
      </div>
      <div class="common-content-row">
        <b>5.1-</b> We reserve the right to terminate or restrict your access to the Website at any
        time,
        without notice.
      </div>

      <div class="common-content-head">
        <span>6</span> Modification of Terms
      </div>
      <div class="common-content-row">
        <b>6.1-</b> We reserve the right to modify these terms of service at any time. The modified
        terms
        will be posted on the Website and will become effective immediately.
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/m_common.vue';

import '@/css/common.scss';

export default {
  name: 'PrivacyMain',
  components: {
    CommonPage
  },
  mixins: [common],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
  }
}
</script>
